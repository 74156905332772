<template>
  <div id="subpage-content">
    <InformsDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <div v-html="storageItemName()"></div>
        <div id="news-contain">
          <img height="200" width="230" :src="storageImageUrl()" href="" id="pic" align="left" />
          <div id="innews">
            <div id="inf-news-carddate">{{ storageItemDate() }}</div>
          </div>
          <span id="pad" v-html="storageItemLongText()"></span>
          <div id="innews">
            <a id="backto" @click="$router.push('/informs/news')">
              <v-icon large>mdi-arrow-left-bold-circle</v-icon>
              <a id="pad-left">{{ $t("message.BackToList") }}</a>
            </a>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import InformsDrawer from "../../../../views/Navigations/Informs/InformsDrawer";
export default {
  components: {
    InformsDrawer,
  },
  data: () => ({
    items: [],
  }),
  methods: {
    storageId() {
      return localStorage.itemid;
    },
    // storageName1() {
    //   return localStorage.itemname1;
    // },
    storageItemName() {
      if (this.$i18n.locale === "ru") {
        return localStorage.itemname1;
      } else if (this.$i18n.locale === "gb") {
        return localStorage.itemname2;
      }
    },
    storageImageUrl() {
      return "data:image/jpeg;base64," + localStorage.itemimage;
    },
    storageItemDate() {
      return localStorage.itemdate;
    },
    storageItemLongText() {
      if (this.$i18n.locale === "ru") {
        return localStorage.itemlongtext1;
      } else if (this.$i18n.locale === "gb") {
        return localStorage.itemlongtext2;
      }
    },
  },
};
</script>
<style></style>
